import request from '@/utils/request'

export function createCommonOrder(payProjectVO) {
  return request({
    url: 'pay/web/common/createCommonOrder',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'payProjectVO': payProjectVO
    }
  })
}
export function createStandardOrder(payProjectVO) {
  return request({
    url: 'pay/web/standard/createStandardOrder',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'payProjectVO': payProjectVO
    }
  })
}
export function getRebateAmount(payAmount, projectId) {
  return request({
    url: 'pay/project/getRebateAmount',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'payAmount': payAmount,
      'projectId': projectId
    }
  })
}
